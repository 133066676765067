
<template>
  <div class="h-screen bg-neu flex flex-col items-center">
      <img class="mt-20 mb-4 w-40" src="../assets/ak-logo.png" alt="akousist-logo">

      <div class="neuBox flex flex-col px-8 py-12 mx-4">
        <p class="neuBox__title mb-4 text-center">智慧法律助理</p>
        <form class="mb-4 w-full"
            @submit.prevent="handleSubmit">
            <div class="">
                <input type="text" v-model="username" name="username" 
                        placeholder="帳號" 
                        class="neuInput w-full mb-4"
                        :class="{ 'is-invalid': submitted && !username }" />
                <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
            </div>
            <div class="">
                <input type="password" v-model="password" name="password" 
                        placeholder="密碼" 
                        class="neuInput w-full mb-4" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="">
                <button class="neuButton w-full text-white px-6 py-1 flex justify-center"
                        :disabled="status.loggedIn"
                        >
                        <span>登入</span>
                </button>
            </div>
        </form>
        <p class="text-sm">忘記帳號密碼請聯繫客服：<a href="mailto:cs@akousist.com">cs@akousist.com</a></p>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            username: '',
            password: '',
            submitted: false
        }
    },
    computed: {
        ...mapState('auth', ['status'])
    },
    methods: {
        ...mapActions('auth', ['login']),
        handleSubmit () {
          const { username, password } = this;
          this.login({ username, password })
        },
        handleLogout() {
          this.logout();
        },
    },
};

</script>

<style scoped>
.bg-neu {
  background: #e0e0e0;
}

.neuBox__title{
  font: bold 32px arial, sans-serif;
  background-color: #565656;
  color: transparent;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.neuBox {
  border-radius: 32px;
  background: var(--my--gray-1);
  color: var(--text--gray-5);
  box-shadow:  var(--nue--convex-md);
}

.neuInput {
  border-radius: 8px;
  background: var(--my--gray-1);
  padding: 5px 10px;
  color: var(--text--gray-5);
  box-shadow: var(--nue--concave-md);
}



</style>
